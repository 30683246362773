import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { handleMobileSearchForm, handleMenu } from '../../actions/index';

import Isvg from 'react-inlinesvg';


import {
    Container,
    Row,
    Col,
    Navbar,
    NavbarBrand,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    Nav, NavItem,
    Carousel,
    CarouselItem,
    CarouselControl,
    CarouselIndicators,
    CarouselCaption

} from 'reactstrap';
import { changeLanguage } from '../../actions';
import Categories from '../../components/categories';

import facebook_icon from '../../assets/svg/facebook-icon.svg'
import instagram_icon from '../../assets/svg/instagram-icon.svg'
import tiktok_icon from '../../assets/svg/tiktok_icon.svg';

import credit_card_icon from '../../assets/svg/credit-card-icon.svg'
import heart_icon from '../../assets/svg/heart-icon.svg'
import account_icon from '../../assets/svg/account-icon.svg'
import cart_icon from '../../assets/svg/cart-icon.svg'
import phone_icon from '../../assets/svg/header-phone.svg'
import mail_icon from '../../assets/svg/header-mail.svg'

import hamburger_icon from '../../assets/svg/hamburger-icon.svg'
import home_icon from '../../assets/svg/home-icon.svg'
import phone1_icon from '../../assets/svg/phone.svg'
import category_icon from '../../assets/svg/category-icon.svg'
import searchIcon from '../../assets/svg/search.svg'

// import logo from '../../assets/svg/logo.svg';
import logo from '../../assets/svg/Rossen-logo-natural.svg';
import image from '../../assets/images/no-image.jpg';


import drawerAccount from '../../assets/svg/drawer/account.svg';
import drawerCart from '../../assets/svg/drawer/cart.svg';
import drawerHome from '../../assets/svg/drawer/home.svg';
import drawerLogout from '../../assets/svg/drawer/logout.svg';
import drawerPhone from '../../assets/svg/drawer/phone.svg';
import drawerWishlist from '../../assets/svg/drawer/wishlist.svg';
import drawerQuestion from '../../assets/svg/question.svg';
import close_icon from '../../assets/svg/close-ico.svg';



import ba from '../../assets/svg/ba.svg';
import sr from '../../assets/svg/sr.svg';
import si from '../../assets/svg/si.svg';
import hr from '../../assets/svg/hr.png';

import eu from '../../assets/svg/eu.png';
import de from '../../assets/images/de.png';
import viberIcon from '../../assets/svg/viber.svg';


import ch from '../../assets/svg/ch.svg';
import me from '../../assets/svg/me.png';
import mk from '../../assets/svg/mk.png';

import se from '../../assets/svg/se_flag.png';
import at from '../../assets/svg/at_flag.png';


class HomeHeader extends Component {
    constructor(props) {
        super(props);
        this.registerSocketIOEvents = this.registerSocketIOEvents.bind(this);
        this.suggestions = this.suggestions.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);

        this.state = {
            suggestions: [],
            search: '',
            imageErrors: {},
            width: 0, height: 0,
            showSearch: false,
            headerHeight: null
        };

        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);


    }





    componentDidMount() {
        if (this.props.socketIOClient) {


            this.registerSocketIOEvents();
        }
        document.addEventListener('mousedown', this.handleClickOutside);
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);

    }

    componentDidUpdate(prevProps) {
        if (this.props.socketIOClient) {
            this.registerSocketIOEvents();
        }

        if (this.headerRef && this.headerRef.scrollHeight && this.headerRef.scrollHeight !== this.state.headerHeight) {
            this.setState({ headerHeight: this.headerRef.scrollHeight }, () => {
                if (typeof window !== 'undefined') {
                    document.body.style.paddingTop = `${this.state.headerHeight}px`;


                }
            })
        }

    }

    registerSocketIOEvents() {
        if (this.state._registeredEvents)
            return;

        if (this.props[0].location.pathname.indexOf('/product') == -1) {
            this.props.fetchSiteInfo();
        }

        this.setState({
            _registeredEvents: true
        });

        this.props.socketIOClient.on('suggestions', (data) => {
            console.log(data);

            this.setState({
                imageErrors: {},
                suggestions: data.products
            })
        });



    }
    updateWindowDimensions() {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
        window.removeEventListener('resize', this.updateWindowDimensions);

        if (!this.props.socketIOClient) return;
        this.props.socketIOClient.removeAllListeners("suggestions");
        //this.props.socketIOClient.removeAllListeners("fetchProductVariations");

    }


    suggestions() {
        if (this.state.search.length) {
            this.props.socketIOClient.emit("suggestions", { search: this.state.search });
        }
    }
    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            this.setState({
                suggestions: []
            })
        }

        if (this.leftMenuRef && !this.leftMenuRef.contains(event.target)) {
            this.setState({
                leftMenu: null
            })
        }

        if (this.rightMenuRef && !this.rightMenuRef.contains(event.target)) {
            this.setState({
                rightMenu: null
            })
        }
    }


    render() {
        return (
            <header ref={node => this.headerRef = node}>
                <Container className="header">
                    <Row>
                        <Col lg={{ size: 1, order: 0 }} xs={{ size: 2, order: 0 }} className='hamburger d-lg-none d-xl-none' onClick={() => { this.setState({ leftMenu: !this.state.leftMenu }) }}>
                            <Isvg src={hamburger_icon} />
                        </Col>

                        <Col xl={{ size: 2, order: 0 }} lg={{ size: 4, order: 0 }} xs={{ size: 7, order: 1 }} sm={{ size: 5, order: 1 }} md={{ size: 8, order: 1 }} className="logo">
                            <Link to='/'><Isvg src={logo} /></Link>
                        </Col>

                        <Col lg={{ size: 5, order: 3 }} xl={{ size: 5, order: 0 }} sm={{ size: 12, order: 5 }} xs={{ size: 12, order: 5 }} className="navigation">
                            <ul>
                                <li className={this.props[0].location.pathname == '/' ? 'active' : null}> <Link to={`/`}> {this.props.translate('POČETNA')} </Link> </li>
                                <li className={this.props[0].location.pathname.indexOf('/category') !== -1 ? 'active' : null}> <Link to={`/category`}> {this.props.translate('PROIZVODI')} </Link> </li>
                                <li className={this.props[0].location.pathname.indexOf('/offer') !== -1 ? 'active bold-text' : 'bold-text'}> <Link to={`/offer`}> {this.props.translate('AKCIJE')} </Link> </li>

                                {/* <li className={this.props[0].location.pathname.indexOf('/skin-consult') !== -1 ? 'active' : null}> <Link to={`/skin-consult`}> {this.props.translate('SKIN CONSULT')} </Link> </li> */}

                                {/*      <li className={this.props[0].location.pathname.indexOf('/blog') !== -1 ? 'active' : null}> <Link to={`/blog`}> BLOG </Link> </li>*/}
                                <li className={this.props[0].location.pathname.indexOf('/contact') !== -1 ? 'active' : null}> <Link to={`/contact`}> {this.props.translate('KONTAKT')} </Link> </li>
                            </ul>
                        </Col>

                        {this.props.uData ?
                            <Col lg={{ size: 4, order: 6 }} md={{ size: 1, order: 6 }} xs={{ size: 1, order: 6 }} className="account d-none d-sm-none d-md-none d-xl-none d-lg-block  ">
                                <div className="account-button">

                                    <Isvg src={account_icon} />
                                    <Link to='/account'>{this.props.translate('Nalog')}</Link>
                                </div>
                                <Link to='/cart' className="cart-button">
                                    <Isvg src={cart_icon} />
                                    <div className="number">{this.props.cartCount}</div>
                                </Link>

                                <div className="social">
                                    <a href={"https://www.facebook.com/profile.php?id=61550036164014"} target="_blank"><Isvg src={facebook_icon} /></a>
                                    <a href={"https://www.instagram.com/rossen_natural_se/?hl=en"} target="_blank"><Isvg src={instagram_icon} /></a>
                                    <a href="https://www.tiktok.com/@rossen_natural_se?_t=8iAlrpLj6dp&_r=1" target="_blank"><Isvg src={tiktok_icon} /></a>
                                </div>
                                <UncontrolledDropdown>
                                    <DropdownToggle>
                                        {this.props.lang == 'se' ? <img src={se} /> : this.props.lang == 'hr' ? <img src={hr} /> : this.props.lang == 'si' ? <Isvg src={si} /> : this.props.lang == 'de' ? <img src={de} /> : <img src={eu} />} {this.props.lang == 'se' ? 'SE' : this.props.lang == 'hr' ? 'CRO/EU' : this.props.lang == 'si' ? 'SI' : this.props.lang == 'de' ? 'DE' : 'EN/EU'}
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        <DropdownItem onClick={() => {
                                            this.props.setLang('se');
                                            /*this.props.setCurrency({
                                              code: 'EUR',
                                              exchangeRate: 0.91                                    
                                            })*/

                                        }}><img src={se} /> SE</DropdownItem>
                                        <DropdownItem onClick={() => {
                                            this.props.setLang('hr');
                                            /*this.props.setCurrency({
                                              code: 'CHF',
                                              exchangeRate: 1                                    
                                            })*/

                                        }}><img src={hr} /> CRO/EU</DropdownItem>
                                        {/* <a href="https://rossen-natural.si"> <DropdownItem><Isvg src={si} /> SI</DropdownItem></a>

                                        <a href="https://rossen-kosmetik.de"> <DropdownItem><img src={de} /> DE</DropdownItem></a> */}
                                        <DropdownItem onClick={() => {
                                            this.props.setLang('en');
                                            /*this.props.setCurrency({
                                              code: 'EUR',
                                              exchangeRate: 0.91                                    
                                            })*/

                                        }}><img src={eu} /> EN/EU</DropdownItem>
                                        {/* <a href="https://rossen.se"> <DropdownItem><Isvg src={ba} /> BA</DropdownItem></a>
                                    <a href="https://sr.rossen.se">   <DropdownItem><Isvg src={sr} /> SR</DropdownItem></a> */}
                                        {/* <a href="https://rossen.com.hr">   <DropdownItem><img src={hr} /> HR</DropdownItem></a> */}
                                        {/* <a href="https://rossen.se">   <DropdownItem><img src={eu} /> EU</DropdownItem></a> */}
{/* 
                                        <a href="https://rossen.shop"> <DropdownItem><Isvg src={ba} /> BA</DropdownItem></a>
                                        <a href="https://rossen.rs">   <DropdownItem><Isvg src={sr} /> SR</DropdownItem></a>

                                        <a href="https://rossen-shop.ch">   <DropdownItem><Isvg src={ch} /> CH</DropdownItem></a>
                                        <a href="https://rossen-shop.me/">   <DropdownItem><img src={me} /> ME</DropdownItem></a>
                                        <a href="https://rossen.mk/">   <DropdownItem><img src={mk} /> MK</DropdownItem></a>
                                        <a href="https://rossen.at">   <DropdownItem><img src={at} /> AT</DropdownItem></a> */}


                                    </DropdownMenu>
                                </UncontrolledDropdown>


                            </Col>
                            :
                            <Col lg={{ size: 5, order: 0 }} xs={{ size: 1, order: 0 }} className="account d-none d-sm-none d-md-none d-xl-block">
                                <div className="account-button">
                                    <Isvg src={account_icon} />
                                    <div className="account-text">
                                        <Link to='/login'>{this.props.translate('Prijava')}</Link>
                                        <Link to='/register'>{this.props.translate('Registracija')}</Link>
                                    </div>

                                </div>

                                <Link to='/cart' className="cart-button">
                                    <Isvg src={cart_icon} />
                                    <div className="number"><span>{this.props.cartCount}</span></div>
                                </Link>
                                <div className="social">
                                    <a href={this.props.lang == 'de' ? "https://www.facebook.com/Rossen-Natural-Austria-101262906270656" : "https://www.facebook.com/profile.php?id=61550036164014"} target="_blank"><Isvg src={facebook_icon} /></a>
                                    <a href={this.props.lang == 'de' ? "https://www.instagram.com/rossen.natural.austria/" : "https://www.instagram.com/rossen_natural_se/?hl=en"} target="_blank"><Isvg src={instagram_icon} /></a>
                                    <a href="https://www.tiktok.com/@rossen_natural_se?_t=8iAlrpLj6dp&_r=1" target="_blank"><Isvg src={tiktok_icon} /></a>
                                </div>

                                <UncontrolledDropdown>
                                    <DropdownToggle>
                                        {this.props.lang == 'se' ? <img src={se} /> : this.props.lang == 'hr' ? <img src={hr} /> : this.props.lang == 'si' ? <Isvg src={si} /> : this.props.lang == 'de' ? <img src={de} /> : <img src={eu} />} {this.props.lang == 'se' ? 'SE' : this.props.lang == 'hr' ? 'CRO/EU' : this.props.lang == 'hr' ? 'CRO/EU' : this.props.lang == 'si' ? 'SI' : this.props.lang == 'de' ? 'DE' : 'EN/EU'}
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        <DropdownItem onClick={() => {
                                            this.props.setLang('se');
                                            /*this.props.setCurrency({
                                              code: 'EUR',
                                              exchangeRate: 0.91                                    
                                            })*/

                                        }}><img src={se} /> SE</DropdownItem>
                                        <DropdownItem onClick={() => {
                                            this.props.setLang('hr');
                                            /*this.props.setCurrency({
                                              code: 'CHF',
                                              exchangeRate: 1                                    
                                            })*/

                                        }}><img src={hr} /> CRO/EU</DropdownItem>
                                        {/* <a href="https://rossen-natural.si"> <DropdownItem><Isvg src={si} /> SI</DropdownItem></a>
                                        <a href="https://rossen-kosmetik.de"> <DropdownItem><img src={de} /> DE</DropdownItem></a> */}

                                        <DropdownItem onClick={() => {
                                            this.props.setLang('en');
                                            /*this.props.setCurrency({
                                              code: 'EUR',
                                              exchangeRate: 0.91                                    
                                            })*/

                                        }}><img src={eu} /> EN/EU</DropdownItem>
                                        {/* <a href="https://rossen.se"> <DropdownItem><Isvg src={ba} /> BA</DropdownItem></a>
                                    <a href="https://sr.rossen.se">   <DropdownItem><Isvg src={sr} /> SR</DropdownItem></a> */}
                                                                            {/* <a href="https://rossen.com.hr">   <DropdownItem><img src={hr} /> HR</DropdownItem></a> */}
                                        {/* <a href="https://rossen.se">   <DropdownItem><img src={eu} /> EU</DropdownItem></a> */}

                                    
                                        {/* <a href="https://rossen.shop"> <DropdownItem><Isvg src={ba} /> BA</DropdownItem></a>
                                        <a href="https://rossen.rs">   <DropdownItem><Isvg src={sr} /> SR</DropdownItem></a>

                                        <a href="https://rossen-shop.ch">   <DropdownItem><Isvg src={ch} /> CH</DropdownItem></a>
                                        <a href="https://rossen-shop.me/">   <DropdownItem><img src={me} /> ME</DropdownItem></a>
                                        <a href="https://rossen.mk/">   <DropdownItem><img src={mk} /> MK</DropdownItem></a>
                                        <a href="https://rossen.at">   <DropdownItem><img src={at} /> AT</DropdownItem></a> */}


                                    </DropdownMenu>
                                </UncontrolledDropdown>

                            </Col>
                        }


                        <Col className='d-lg-none d-xl-none d-md-block' xs={{ size: 3, order: 4 }} md={{ size: 2, order: 4 }} className="mobile-actions">
                            <Link to='/cart'><Isvg src={cart_icon} />{this.props.cartCount ? <div className="cart-count">{this.props.cartCount}</div> : null}</Link>
                            {/*<button onClick={() => { this.setState({ rightMenu: !this.state.rightMenu }) }}>
                                <Isvg src={category_icon} />
                            </button>
                    */}
                            <UncontrolledDropdown>
                                <DropdownToggle>
                                    {this.props.lang == 'se' ? <img src={se} /> : this.props.lang == 'hr' ? <img src={hr} /> : this.props.lang == 'si' ? <Isvg src={si} /> : this.props.lang == 'de' ? <img src={de} /> : <img src={eu} />} {this.props.lang == 'se' ? 'SE' : this.props.lang == 'hr' ? 'CRO' : this.props.lang == 'si' ? 'SI' : this.props.lang == 'de' ? 'DE' : 'EN/EU'}
                                </DropdownToggle>
                                <DropdownMenu>
                                    <DropdownItem onClick={() => {
                                        this.props.setLang('se');
                                        /*this.props.setCurrency({
                                          code: 'EUR',
                                          exchangeRate: 0.91                                    
                                        })*/

                                    }}><img src={se} /> SE</DropdownItem>
                                    <DropdownItem onClick={() => {
                                        this.props.setLang('hr');
                                        /*this.props.setCurrency({
                                          code: 'CHF',
                                          exchangeRate: 1                                    
                                        })*/

                                    }}><img src={hr} /> CRO/EU</DropdownItem>
                                    {/* <a href="https://rossen-natural.si"> <DropdownItem><Isvg src={si} /> SI</DropdownItem></a>


                                    <a href="https://rossen-kosmetik.de"> <DropdownItem><img src={de} /> DE</DropdownItem></a> */}


                                    <DropdownItem onClick={() => {
                                        this.props.setLang('en');
                                        /*this.props.setCurrency({
                                          code: 'EUR',
                                          exchangeRate: 0.91                                    
                                        })*/

                                    }}><img src={eu} /> EN/EU</DropdownItem>
                                    {/* <a href="https://rossen.se"> <DropdownItem><Isvg src={ba} /> BA</DropdownItem></a>
                                    <a href="https://sr.rossen.se">   <DropdownItem><Isvg src={sr} /> SR</DropdownItem></a> */}
                                                                       {/* <a href="https://rossen.com.hr">   <DropdownItem><img src={hr} /> HR</DropdownItem></a> */}
                                    {/* <a href="https://rossen.se">   <DropdownItem><img src={eu} /> EU</DropdownItem></a> */}

                                   
                                    {/* <a href="https://rossen.shop"> <DropdownItem><Isvg src={ba} /> BA</DropdownItem></a>
                                    <a href="https://rossen.rs">   <DropdownItem><Isvg src={sr} /> SR</DropdownItem></a>

                                    <a href="https://rossen-shop.ch">   <DropdownItem><Isvg src={ch} /> CH</DropdownItem></a>
                                    <a href="https://rossen-shop.me/">   <DropdownItem><img src={me} /> ME</DropdownItem></a>
                                    <a href="https://rossen.mk/">   <DropdownItem><img src={mk} /> MK</DropdownItem></a>
                                    <a href="https://rossen.at">   <DropdownItem><img src={at} /> AT</DropdownItem></a> */}


                                </DropdownMenu>
                            </UncontrolledDropdown>

                        </Col>
                    </Row>

                </Container>

                <div className={this.state.leftMenu ? 'mobile-left-menu mobile-left-menu-open' : 'mobile-left-menu'}>
                    <div className="content" ref={(node) => this.leftMenuRef = node}>
                        <div className="user-area">
                            <div className="user-image">
                                <Isvg src={account_icon} />
                            </div>

                            {this.props.uData && <p>{this.props.uData.User.Name}</p>}
                            {this.props.uData && <p>{this.props.uData.User.EMail}</p>}
                            {!this.props.uData && <p><Link to='/login'>{this.props.translate('Prijava')}</Link> / <Link to='/register'>{this.props.translate('Registracija')}</Link></p>}

                        </div>

                        <ul>
                            <li> <Link to='/'><Isvg src={drawerHome} />{this.props.translate('Početna')} </Link> </li>

                            {this.props.uData && <li> <Link to='/account'><Isvg src={drawerAccount} /> {this.props.translate('Nalog')} </Link> </li>}
                            <li> <Link to='/cart'><Isvg src={drawerCart} />{this.props.translate('Korpa')} </Link> </li>
                            {this.props.uData && <li> <Link to='/account/wishlist'><Isvg src={drawerWishlist} />{this.props.translate('Lista omiljenih')} </Link> </li>}
                            <li> <Link to='/page/kako-naruciti'><Isvg src={drawerQuestion} />{this.props.translate('Kako naručiti')} </Link> </li>

                            <li> <Link to='/contact'><Isvg src={drawerPhone} />{this.props.translate('Kontakt')} </Link> </li>

                            {this.props.uData && <li> <a onClick={() => { this.props.setUserData(null); this.props.socketIOClient.emit('userLogout', {}); }}><Isvg src={drawerLogout} />{this.props.translate('Odjava')} </a> </li>}
                            {!this.props.uData && <li> <Link to='/account'><Isvg src={drawerAccount} />{this.props.translate('Prijava')} </Link> </li>}

                        </ul>
                    </div>
                </div>

                <div className={this.state.rightMenu ? 'mobile-right-menu mobile-right-menu-open' : 'mobile-right-menu'}>
                    <div className="content" ref={(node) => this.rightMenuRef = node}>
                        <h3>{this.props.translate('KATEGORIJE')}</h3>

                        <button className="close" onClick={() => this.setState({ rightMenu: false })}>
                            <Isvg src={close_icon} />
                        </button>


                        <Categories {...this.props} selectCategory={(cat, level) => {
                            let state = { page: 0 };
                            state['_selectedCategory' + level] = cat._id;

                            this.setState(state);

                        }}

                            _selectedCategory0={this.state._selectedCategory0}
                            _selectedCategory1={this.state._selectedCategory1}
                            _selectedCategory2={this.state._selectedCategory2}
                            _selectedCategory3={this.state._selectedCategory3}
                            _selectedCategory4={this.state._selectedCategory4}


                        />


                    </div>
                </div>
                <div className="shipping-info">
                    <a href="viber://chat?number=%2B38670499736" className="viber-orders">
                        <Isvg src={viberIcon} />

                        {/* {this.props.translate('Viber narudžbe')}  */}
                        <span>+46736956465 </span>
                    </a>

                    {
                        new Date().getTime() >= new Date(2024, 8, 27, 16, 0, 0, 0).getTime() && new Date().getTime() <= new Date(2024, 8, 29, 23, 59, 59, 999).getTime() ?
                            this.props.translate('BESPLATNA DOSTAVA na iznos iznad 50KM')
                            :
                            this.props.translate('BESPLATNA DOSTAVA na iznos iznad 100KM')
                    }
                    {/* {this.props.translate('(Slovenija, Hrvatska, Austrija, Njemačka)')} */}
                </div>
                {
                    new Date().getTime() >= new Date(2023, 0, 27, 20, 0, 0, 0).getTime() && new Date().getTime() <= new Date(2023, 0, 29, 23, 59, 59, 999).getTime() ?
                        <div className="shipping-info" style={{ borderTop: '2px solid #f2f2f2', backgroundColor: '#008880', color: '#fff' }}>
                            {this.props.translate('GRATIS POŠTARINA NA KUPOVINU SETOVA')}<span style={{ fontWeight: 500 }}>&nbsp;{this.props.translate('do nedjelje.')}</span>

                        </div>
                        :
                        null
                }

                {
                    this.props.freeDeliveryProductsMessage ?
                        this.props.freeDeliveryProductsLink ?
                            <div className="shipping-info link-text-remove-style" style={{ borderTop: '2px solid #f2f2f2', backgroundColor: '#008880', color: '#fff' }}>
                                <Link to={this.props.freeDeliveryProductsLink}>{this.props.freeDeliveryProductsMessage}</Link>
                            </div>
                            :
                            <div className="shipping-info" style={{ borderTop: '2px solid #f2f2f2', backgroundColor: '#008880', color: '#fff' }}>
                                {this.props.freeDeliveryProductsMessage}
                            </div>
                        :
                        null
                }
                {
                    this.props.freeDeliveryProductsMessage2 ?
                        this.props.freeDeliveryProductsLink2 ?
                            <div className="shipping-info link-text-remove-style" style={{ borderTop: '2px solid #f2f2f2', backgroundColor: '#008880', color: '#fff' }}>
                                <Link to={this.props.freeDeliveryProductsLink2}>{this.props.freeDeliveryProductsMessage2}</Link>
                            </div>
                            :
                            <div className="shipping-info" style={{ borderTop: '2px solid #f2f2f2', backgroundColor: '#008880', color: '#fff' }}>
                                {this.props.freeDeliveryProductsMessage2}
                            </div>
                        :
                        null
                }

                {/* {
                    true ?
                        <div className="shipping-info" style={{ borderTop: '2px solid #f2f2f2', backgroundColor: '#008880', color: '#fff' }}>
                            {this.props.translate('Webbsidan är för närvarande under konstruktion. Snart kommer den kunna användas.')}
                        </div>
                        :
                        null
                } */}

                {
                    new Date().getTime() >= new Date(2023, 9, 28, 0, 0, 0, 0).getTime() && new Date().getTime() <= new Date(2023, 10, 12, 23, 59, 59, 999).getTime() ?
                        <div className="shipping-info" style={{ borderTop: '2px solid #f2f2f2', backgroundColor: '#008880', color: '#fff' }}>
                            {this.props.translate('PROMO AKCIJA -15% NA SVE PROIZVODE!')}
                        </div>
                        :
                        null
                }

            </header>

        );
    }
}

const mapStateToProps = state => ({
    searchForm: state.searchForm,
    menu: state.menu
});

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        handleChange: (val) => {
            dispatch(handleMobileSearchForm(val))
        },
        handleMenu: (val) => {
            dispatch(handleMenu(val))
        }
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(HomeHeader);
